import store from '@/store'
import moment from 'moment'
import postalCodes from 'norway-postal-codes'
import validator from 'norsk-validator'
import { firestore } from '@/services/firebase'
import { openNotification, contractAPI2 } from '@/services/powerAutomate'
import axios from 'axios'

export function calculateBirthday (fnr) {
  // add support for D-number
  if (fnr.length > 5) {
    const day = fnr.slice(0, 2)
    const month = fnr.slice(2, 4)
    let year = fnr.slice(4, 6)
    if (year > 20) {
      year = 19 + year
    } else {
      year = 20 + year
    }
    return year.concat('-', month, '-', day)
  }
}

export function calculateAge (dob) {
  const birthday = new Date(dob)
  const diff = Date.now() - birthday.getTime()
  const ageDate = new Date(diff)
  return Math.abs(ageDate.getUTCFullYear() - 1970)
}

export function calculateGender (fnr) {
  // fnr needs to be 11 digits
  if (fnr.length === 11) {
    // check if 9th digit is odd or even
    if (fnr[8] % 2 === 0) {
      return 'Female'
    } else {
      return 'Male'
    }
  } else {
    throw new Error('Invalid FNR')
  }
}

export function validateBank(rule, value, callback) {
  let data = value
  data = data.replace(/[\s.a-zA-Z@#$%^&*()_+=[\]{}|\\;:'",.<>/?~`]/g, '')
  this.data.bankAccount = data
  if (value.length === 11) {
    if (!validator.kontonummer(data)) {
      callback(new Error('Ugyldig kontonummer'))
    } else {
      callback()
    }
  } else if (value.length > 0) {
    callback(new Error('Alt for kort kontonummer'))
  }
  this.form.setFieldsValue({ bankAccount: data })
}

// export function positionsSelector (fnr) {
//   if (fnr.length > 5) {
//     const day = fnr.slice(0, 2)
//     const month = fnr.slice(2, 4)
//     let year = fnr.slice(4, 6)
//     if (year > 20) {
//       year = 19 + year
//     } else {
//       year = 20 + year
//     }
//     return year.concat('-', month, '-', day)
//   }
// }

export async function createPosition(employee, position) {
  position.type = position.type === 'custom' ? position.customType : position.type
  position.signedContract = false
  position.status = 'active'
  position.startDate = String(moment(position.startDate).format('YYYY-MM-DD'))
  if (position.endPrevious) {
    employee.positions.forEach((p, i) => {
      if (!p.endDate) {
        employee.positions[i].endDate = String(moment(position.startDate).subtract(1, 'days').format('YYYY-MM-DD'))
        employee.positions[i].reason = 'Endring av stilling internt'
        employee.positions[i].status = 'inactive'
      }
    })
  }
  return firestore.collection('variables').doc('employeeID').get()
    .then(doc => {
      position.id = String(Number(doc.data().lastPositionID) + 1)
      if (employee.positions) employee.positions.push(position)
      if (!employee.positions) {
        employee.positions = []
        employee.positions[0] = position
      }
      return firestore.collection('users').doc(employee.uid).update({
        positions: employee.positions,
      })
    }).then(() => {
      openNotification('Stilling opprettett')
    }).catch(err => {
      openNotification('Feil ved oprettelse av stilling', 'Vennligst prøv igjen')
      employee.positions.pop()
      console.log(err)
      throw new Error(err)
    }).finally(() => {
      return employee
    })
}

export async function createContract(employee, position, index) {
  console.log(employee, position)
  const company = {
    name: 'Syrus',
    phone: '+4741860278',
    email: 'info@syrus.no',
    url: 'https://syrus.no',
  }
  const customFields = {
    percentage: position.percentage,
    fixedMonthly: parseFloat(position.fixedMonthly).toLocaleString('no'),
  }
  // get department address
  // get company data
  let docTemplate
  await firestore.collection('settings').doc('contracts').collection('contracts').doc(position.contractId)
    .get()
    .then(doc => {
      docTemplate = doc.data().docTemplate
    })
  await contractAPI2(employee, position, index, docTemplate, store.state.user, company, customFields).then(() => openNotification('Kontrakt Sendt til godkjenning')).catch(err => openNotification('Feil ved sending av kontrakt', err))
}

export function findCity(zip) {
  if (zip.length === 4) {
    const city = postalCodes[zip]
    if (city) {
      return city
    } else {
      this.openNotification('Feil postnummer', 'Postnummer finnes ikke, vennligst sjekk igjen')
    }
  } else {
    return ''
  }
}

export function updateEmployee (employee) {
  console.log('update', employee)
  firestore.collection('users').doc(employee.uid)
    .update(employee)
}

export function selectAvDepartments(deps) {
  const avDeps = []
  deps.forEach(dep => {
    avDeps.push({
      value: dep,
      label: dep,
    })
  })
  return avDeps
}

export function deactivateTelenorFiberField(data) {
  const talkmoreOldUser = firestore.collection('tasks').doc('telenorOldUser')
  talkmoreOldUser.set({
    title: 'Deaktivere Telenor Brukere',
    description: 'Følgende ansatte har sluttet og har telenor brukere i salesforce og Motimate <br> Vennligst sjekk gjennom ansatt listen og trykk på knappen nede for å sende til deaktivering',
    date: String(moment().format('YYYY-MM-DDTHH:mm')),
    icon: 'fa fa-user',
    type: 'orderTNFIBERuser',
    subTasksActionIcon: 'fa fa-trash',
    status: 'to-do',
    subTasksAction: true,
    callbacks: [
      {
        fn: 'deactivateTelenorUsers',
        name: 'Send deaktivering brukere',
      },
    ],
    hasSubtasks: true,
  })
  talkmoreOldUser.collection('subtasks').add({
    title: data.fullName,
    fnValues: {
      email: data.companyEmail,
      fullName: data.fullName,
    },
  })
  delete data.telenorFiber
  return data
}

export function deactivateTalkmoreUsers(data) {
  const talkmoreOldUser = firestore.collection('tasks').doc('talkmoreOldUser')
  talkmoreOldUser.set({
    title: 'Deaktivere Talkmore Brukere',
    description: 'Følgende ansatte har sluttet og har talkmore ep-bruker <br> Vennligst sjekk gjennom ansatt listen og trykk på knappen nede for å sende til deaktivering',
    date: String(moment().format('YYYY-MM-DDTHH:mm')),
    icon: 'fa fa-user',
    type: 'orderTMB2Cuser',
    subTasksActionIcon: 'fa fa-trash',
    subTasksAction: true,
    callbacks: [
      {
        fn: 'dectivateTmb2cUsers',
        name: 'Send deaktivering brukere',
      },
    ],
    hasSubtasks: true,
  })
  talkmoreOldUser.collection('subtasks').add({
    title: data.fullName.concat(' ', data.talkmoreB2C),
    fnValues: {
      epuser: data.talkmoreB2C,
      name: data.name,
      surname: data.surname,
      phone: data.phone,
    },
  })
  delete data.talkmoreB2C
  return data
}

export function deactivateFjordkraftPortal(data) {
  firestore.collection('tasks').add({
    description: `Vennligst deaktievr brukeren til ${data.employeeID} ${data.fullName} i webtabellen <br> Brukeren hans er ${data.email}`,
    icon: 'fa fa-group',
    type: 'fjordkraftWTUsers',
    title: `Deaktiver webtabell bruker til ${data.fullName}`,
    attachments: [
      {
        url: 'https://auth-admin.fjordkraft.no/External/1/788',
        name: 'Gå til Fjordkraft Brukeradministrasjon',
        type: 'link',
      },
    ],
    status: 'to-do',
    date: String(moment().format('YYYY-MM-DDTHH:mm')),
  })
}

export async function getOccupationCodes() {
  return axios.get('https://data.ssb.no/api/klass/v1/versions/683.json')
    .then(res => {
      const codes = []
      res.data.classificationItems.forEach(code => {
        codes.push({
          value: code.code,
          label: code.name,
        })
      })
      return codes
    })
    .catch(err => {
      console.log(err)
      openNotification('Feil ved henting av yrkeskoder', 'Vennligst prøv igjen')
    })
}

export const salaryTypes = [
  { value: 'fixedMonthly', label: 'Fast Månedslønn' },
  { value: 'guaranteeMonthly', label: 'Månedlig Garanti' },
  { value: 'hourly', label: 'Timelønn' },
  { value: 'guaranteeHourly', label: 'Garanti Timelønn' },
  { value: 'noFixed', label: 'Ingen fastlønn' },
]
